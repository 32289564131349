/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Link } from 'gatsby';

interface IndexCardProps {
  num?: string
  imageSource: string
  link: string
  state?: any
  title: string
  description?: string
}
  
const IndexCard: React.FunctionComponent<IndexCardProps> = ({ num, imageSource, link, state, title, description }) => {
  if (link && link !== "") {
    return (
      <Link to={link} state={state}>      
        <div className="h-full bg-dstl-light-gray overflow-hidden hover:bg-dstl-dark-gray p-4">
          <div className="grid grid-cols-2">
            <img className="max-h-48" alt="Section" src={imageSource} />
            <div className="flex-col justify-start text-left px-4">
              {num && <div className="text-xl font-bold">{num}</div>}
              <div className="text-lg font-bold mb-4">{title}</div>
              {description && <div>{description}</div>}
            </div>
          </div>
        </div>
      </Link>);
  }
  
  return (
    <div className="h-full bg-dstl-light-gray overflow-hidden hover:bg-dstl-dark-gray p-4">
      <div className="grid grid-cols-2">
        <img alt="Section" src={imageSource} />
        <div className="flex-col justify-start text-left px-4">
          <div className="text-xl font-bold">{num}</div>
          <div className="text-lg font-bold mb-4">{title}</div>
          {description && <div>{description}</div>}
        </div>
      </div>
    </div>
  );
  
}

export default IndexCard;