/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby';
import showdown from 'showdown';
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import IndexCard from '../../components/IndexCard';
import { Scenario } from '../../types/Global';

interface Props {
  data: {
    scenariosCsv: Scenario
  }
}

const ScenarioPageTemplate: React.FunctionComponent<Props> = ({ data }) => {
  const { slug, title, introduction, narrativeTitle, narrativeImage, dataTitle, dataImage, personasTitle, personasImage, swotTitle, swotImage } = data.scenariosCsv;
  const converter = new showdown.Converter();
  return (
    <Layout
      pageHeader={title}
      breadcrumbs={[{ label: 'Scenarios', link: '/scenarios' }]}
      moreInfo="scenarios/individual"
    >
      <SEO title={title} />
      <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
        <div className="mb-8 text-left">
          <p><strong>Introduction:</strong></p>
          <div dangerouslySetInnerHTML={{ __html: `${converter.makeHtml(introduction)}` }} />
        </div>

        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <IndexCard imageSource={narrativeImage} link={`/scenarios/${slug}/narrative`} title="Scenario Narrative" description={narrativeTitle} />
            <IndexCard imageSource={dataImage} link={`/scenarios/${slug}/data`} title="Scenario In Data" description={dataTitle} />
            <IndexCard imageSource={personasImage} link={`/scenarios/${slug}/personas`} title="Scenario Personas" description={personasTitle} />
            <IndexCard imageSource={swotImage} link={`/scenarios/${slug}/swot`} title="SWOT Analysis" description={swotTitle} />
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String) {
    scenariosCsv(id: { eq: $id }) {
      id
      priority
      title
      slug
      description
      introduction
      image
      narrativeImage
      narrativeTitle
      dataImage
      dataTitle
      personasImage
      personasTitle
      swotImage
      swotTitle
    }    
  }
`

export default withRoleRequired(ScenarioPageTemplate);
